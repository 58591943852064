import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useEffect } from "react";
import "./header.scss";
import "./headerBottomAccent.scss";
import headerAccent from "../../../../images/header/headerAccent.svg";
import { useCallback } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import useFindHeader from "../../../../core/hooks/useFindHeader";
import ButtonContainer from "../../../basics/button/buttonContainer";
import Button from "../../../basics/button/button";

/**
 * Header compontent with image
 *
 * @param {object} queryData - queryData from the graphql query
 * @param {string} title - title of the header
 * @param {string} subTitle - subTitle of the header
 * @param {object} primaryCta - primary Call to action button data
 * @param {object} secondaryCta - secondary call to action button data
 * @param {boolean} bottomAccent - bottom accent div toggle
 * @param {boolean} parallax - parallax animation effect on bacground
 * @param {string} image - image data
 * @returns {React.ReactElement}
 */

const Header = ({ queryData, bottomAccent = true, parallax = false }) => {
  const headerData = useFindHeader(queryData);

  //animation
  const [scale, setScale] = useState(1);
  const [translateY, setTranslateY] = useState(0);

  const parallaxEffect = useCallback(() => {
    if (typeof window !== "undefined") {
      if (window.innerHeight >= window.scrollY) {
        setScale(1 + (window.scrollY / window.innerHeight) * 0.1);
        setTranslateY(window.scrollY * 0.4);
      }
    }
  }, []);

  useEffect(() => {
    parallax &&
      document.addEventListener("scroll", parallaxEffect, { passive: true });
    return () => {
      parallax &&
        document.removeEventListener("scroll", parallaxEffect, {
          passive: true,
        });
    };
  }, []);

  const imageData = getImage(headerData?.image);
  return (
    <header className="site-header centered-header">
      <div className="header-content">
        <h1>{headerData.title}</h1>
        <p className="header-text">{headerData.subTitle}</p>
        <ButtonContainer>
          {headerData?.primaryCta ? (
            <Button
              size="large"
              externalLink={headerData?.primaryCta?.isExternal}
              text={headerData?.primaryCta?.text}
              url={headerData?.primaryCta?.link}
            />
          ) : null}
          {headerData?.secondaryCta ? (
            <Button
              size="large"
              type="altBlock"
              externalLink={headerData?.secondaryCta?.isExternal}
              text={headerData?.secondaryCta?.text}
              url={headerData?.secondaryCta?.link}
            />
          ) : null}
        </ButtonContainer>
      </div>

      {imageData && (
        <motion.div
          style={{ scale: scale, translateY: translateY }}
          className="header-backdrop"
        >
          <GatsbyImage loading="eager" image={imageData} alt="" />
        </motion.div>
      )}
      <div className="scroll-down-icon"></div>
      <span className="scroll-down-icon-text">Scroll Down</span>
      {bottomAccent && (
        <img className="header-bottom-accent" alt="" src={headerAccent} />
      )}
    </header>
  );
};
export default Header;

import * as React from "react";
import Header from "./withbackdrop/header";
import Seo from "../../basics/seo/seo.jsx";
import { getSrc } from "gatsby-plugin-image";
import NoBackdropHeader from "./nobackdrop/noBackdropHeader";
/**
 * Generalised page header with built in Seo and header
 * @param {object} pageData - queryData from the grapqhl query
 * @returns {React.ReactElement}
 */

const PageHeader = ({ pageData }) => {
  const headerData = pageData?.header || pageData?.tartalomTpus?.header;
  const imageURL = getSrc(headerData?.bortkp);
  const hasImage = headerData?.bortkp ? true : false;

  return (
    <>
      <Seo
        title={pageData.cm}
        description={pageData.alcm}
        url={
          pageData?.kategria?.slug
            ? `${pageData?.kategria?.slug}/${pageData.slug}`
            : pageData.slug
        }
        imageUrl={imageURL}
      />
      {hasImage
        ? headerData && <Header queryData={headerData} />
        : headerData && <NoBackdropHeader queryData={headerData} />}
    </>
  );
};
export default PageHeader;
